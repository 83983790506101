<template>
  <div>
    <div id="container" class="mt-2 w-full">
      <canvas id="webgl-canvas"></canvas>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js'
import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'
import _ from 'lodash'

export default {
  props: {
    hobject: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      renderer: null,
      scene: null,
      camera: null,
      controls: null,
      mannequin: null,
      pointCloudModel: null,
      containerWidth: 0,
      containerHeight: 0,
      gltfLoader: null,
      plyLoader: null,
      mixer: null, // Add mixer to data
      clock: new THREE.Clock(), // Add clock to data
      cameraStartPosition: new THREE.Vector3(-2, 4, 6), // Initial camera position
      cameraEndPosition: new THREE.Vector3(-0.5, 1, 1.5), // Final camera position
      cameraStartQuaternion: new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, 0)), // Initial camera rotation
      cameraEndQuaternion: new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, 0)), // Final camera rotation
      animationDuration: 3, // Duration of the animation in seconds
      animationProgress: 0, // Progress of the animation
      animationCompleted: false, // Track if animation is completed
      oldHobject: _.cloneDeep(this.hobject), // Store a deep copy of the initial hobject
    }
  },
  mounted() {
    this.setupScene()
    this.loadMannequin()
    this.loadPLYPointCloud()
    this.animate()
    window.addEventListener('resize', this.onWindowResize)
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrameId)
    window.removeEventListener('resize', this.onWindowResize)
  },
  watch: {
    hobject: {
      handler(newVal) {
        if (newVal.asset_uri !== this.oldHobject.asset_uri) {
          // Remove previous sphere if asset_uri has changed
          this.removePLYPointCloud()
          // Re-create the immersive sphere preview with updated hobject
          this.loadPLYPointCloud()
        } else if (newVal.abilities && this.oldHobject.abilities) {

          const newAbilities = JSON.parse(newVal.abilities)
          const oldAbilities = JSON.parse(this.oldHobject.abilities)

          if (newAbilities.point_cloud_loader.point_size !== oldAbilities.point_cloud_loader.point_size) {
            this.updatePointSize(newAbilities.point_cloud_loader.point_size)
          }
        }
        // Update the oldHobject with the new value
        this.oldHobject = _.cloneDeep(newVal)
      },
      deep: true,
    },
  },
  methods: {
    setupScene() {
      const container = document.getElementById('container')
      this.containerWidth = container.offsetWidth
      this.containerHeight = container.offsetHeight || 600

      // Renderer
      this.renderer = new THREE.WebGLRenderer({ 
        canvas: document.getElementById('webgl-canvas'), 
        antialias: true, 
        alpha: true 
      })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.containerWidth, this.containerHeight)
      this.renderer.outputEncoding = THREE.sRGBEncoding

      // Scene
      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color(0xeeeeee)

      // Camera settings (start pos and fov) must be same as Floor3dScene
      this.camera = new THREE.PerspectiveCamera(70, this.containerWidth / this.containerHeight, 0.01, 30000)
      this.camera.position.copy(this.cameraStartPosition)
      //this.camera.position.set(-2, 4, 6)
      this.camera.quaternion.copy(this.cameraStartQuaternion)

      // Lights
      const ambientLight = new THREE.AmbientLight(0x858585)
      this.scene.add(ambientLight)
      const hemiLight = new THREE.HemisphereLight(0xcccccc, 0x111111)
      hemiLight.position.set(0, 20, -10)
      this.scene.add(hemiLight)
      const dirLight = new THREE.DirectionalLight(0xcccccc)
      dirLight.position.set(-5, 20, 10)
      dirLight.castShadow = true
      this.scene.add(dirLight)

      // Grid (using simple Three.js GridHelper)
      const gridHelper = new THREE.GridHelper(20, 20, 0xcdcdcd, 0x878787)
      this.scene.add(gridHelper)

      // Orbit controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enabled = false // Disable controls initially
      // Set end position and target for camera
      this.controls.target.set(0, 0.8, 0)
      this.controls.maxPolarAngle = Math.PI / 2
      this.controls.minDistance = 1
      this.controls.maxDistance = 100
      this.controls.update()

      // Prepare loader for PLY point cloud
      this.plyLoader = new PLYLoader()

      // Prepare loader for mannequin
      this.gltfLoader = new GLTFLoader()
    },
    loadMannequin() {
      // Load a mannequin model as a size reference (adjust path as needed)
      this.gltfLoader.load(
        `${window.location.origin}/models/mannequin.glb`,
        (gltf) => {
          const mannequinGroup = gltf.scene
          mannequinGroup.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true
            }
          })
          var bbox = new THREE.Box3().setFromObject(mannequinGroup)
          var cent = bbox.getCenter(new THREE.Vector3())
          var size = bbox.getSize(new THREE.Vector3())
          bbox.setFromObject(mannequinGroup)
          bbox.getCenter(cent)
          bbox.getSize(size)
          // Reposition to 0,halfY,0
          mannequinGroup.position.copy(cent).multiplyScalar(-1)
          mannequinGroup.position.y += size.y * 0.5

          mannequinGroup.position.x = 0.07
          mannequinGroup.position.z = 1.25

          mannequinGroup.rotateY(-Math.PI)

          // Play animation
          this.mixer = new THREE.AnimationMixer(mannequinGroup)
          var action = this.mixer.clipAction(gltf.animations[0])
          action.clampWhenFinished = true
          action.loop = THREE.LoopOnce
          action.play()

          this.mannequin = mannequinGroup
          this.scene.add(this.mannequin)
        },
        undefined,
        (error) => {
          console.error('Error loading mannequin:', error)
        }
      )
    }, 
    async removePLYPointCloud() {
      if (this.pointCloudModel) {
        this.scene.remove(this.pointCloudModel)
        this.pointCloudModel = null
      }
    },
    async loadPLYPointCloud() {
      try {
        const pointCloudModel = await HoverlayThreeUtils.createPointCloud(this.hobject, this.plyLoader)
        // The sphere may already be pivoted at the bottom.
        this.pointCloudModel = pointCloudModel

        // Set point cloud model position to origin
        var origin = new THREE.Vector3(0, 0, 0)
        this.pointCloudModel.position.copy(origin)

        this.scene.add(pointCloudModel)
      } catch(e) {
        console.error('Error creating immersive sphere:', e)
      }
    },
    updatePointSize(pointSize) {
      if (this.pointCloudModel) {
        this.pointCloudModel.material.setSize(pointSize);
        this.pointCloudModel.material.needsUpdate = true
      }
    },
    animate() {
      this.animationFrameId = requestAnimationFrame(this.animate)
      if (this.controls) this.controls.update()
      if (this.mixer) this.mixer.update(0.016) // Update mixer with a fixed time step

        // Update animation progress
        const delta = this.clock.getDelta()
        this.animationProgress += delta
        const t = Math.min(this.animationProgress / this.animationDuration, 1) // Clamp t to [0, 1]
        const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t // Easing function

        if (!this.animationCompleted) {
        // Enable controls after animation completes
          if (t >= 1) {
            this.controls.enabled = true
            this.animationCompleted = true
          } else
          {
            // Interpolate camera position and rotation
            const easedT = easeInOutQuad(t)
            this.camera.position.lerpVectors(this.cameraStartPosition, this.cameraEndPosition, easedT)
            this.camera.quaternion.slerp(this.cameraEndQuaternion, easedT)
          }
      }

      this.controls.enabled = true
      this.renderer.render(this.scene, this.camera)
    },
    onWindowResize() {
      const container = document.getElementById('container')
      this.containerWidth = container.offsetWidth
      this.containerHeight = container.offsetHeight || 6003
      this.camera.aspect = this.containerWidth / this.containerHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(this.containerWidth, this.containerHeight)
    },
  },
}
</script>

<style scoped lang="scss">
#webgl-canvas {
  width: 100%;
  height: 100%; /* Ensure the canvas takes the full height of the container */
  display: block;
}
#container {
  position: relative;
  width: 100%;
  height: 100%; /* Ensure the container takes the full height set by the parent */
  border-radius: 6px;
  overflow: hidden;
}
</style>
