<!-- =========================================================================================
  File Name: Cutout.vue
  Description: Form to edit the atributes of an image cutout hoverpack
========================================================================================== -->

<template>
  <div>
    <div class="flex">
      <vs-checkbox v-model="showAdvanced" class="mr-3" :class="{'text-grey': !isHobjectAbilitiesEnable}" :disabled="!isHobjectAbilitiesEnable">
        Advanced
      </vs-checkbox>
      <span v-if="!isHobjectAbilitiesEnable" class="text-primary">
        <vx-tooltip text="Upgrade plan for access to advanced settings" position="right">
          <feather-icon icon="LockIcon" class="pl-2 inline-flex rounded-full"></feather-icon>
        </vx-tooltip>
      </span>
    </div>
    <div v-show="showAdvanced">
      <div slot="header" style="font-size: 0.85rem; color: rgba(0, 0, 0, 0.7)">Advanced</div>
      <div>
        <vue-json-editor
          height="400px"
          v-model="json"
          :show-btns="false"
          :expandedOnStart="true"
          @has-error="onJsonError"
          @json-change="onJsonChange"
        ></vue-json-editor>
      </div>
    </div>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
  inject: ['$validator'],
  components: {
    vueJsonEditor,
  },
  props: {
    initialPlacement: {},
    hobject: {},
    isHobjectAbilitiesEnable: Boolean,
  },
  data() {
    return {
      showAdvanced: false,
      json: null,
    }
  },
  watch: {
    hobject: {
      handler: function (updatedValue) {
        this.$emit('setHobject', updatedValue)
        this.json = JSON.parse(this.hobject.abilities)
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.json = JSON.parse(this.hobject.abilities)
  },
  methods: {
    onJsonError() {
      console.log('ERROR')
    },
    onJsonChange(updatedValue) {
      // console.log('value:', updatedValue)
      this.hobject.abilities = JSON.stringify(updatedValue, null, 2)
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
