<!-- filepath: /Users/nicdev/Hoverlay/Dev/spaces.hoverlay.com/src/views/hoverlay/components/objects-form/FormImmersiveSphere.vue -->
<template>
  <div>
    <label class="vs-input--label">Immersion Level</label>
    <vs-slider
      ticks
      step="1"
      class="mb-5"
      text-fixed="%"
      :step-decimals="true"
      v-model="inner_edge_distance"
    />
    
    <label class="vs-input--label">Edge</label>
    <vs-slider
      ticks
      step="1"
      class="mb-5"
      text-fixed="%"
      :step-decimals="true"
      v-model="edge_width"
    />
    
    <vs-switch v-model="grounded" class="mb-5">
      <span slot="on">Grounded</span>
      <span slot="off">Not Grounded</span>
    </vs-switch>

    <div v-if="grounded">
      <label for="camera-elevation" class="vs-input--label">360 Camera Ground Elevation (in m)</label>
      <vs-input
        id="camera-elevation"
        type="number"
        step="0.1"
        v-model.number="camera_elevation"
        class="mb-5"
        placeholder="Enter camera elevation"
      />
    </div>

    <label for="radius" class="vs-input--label">Sphere Radius</label>
    <vs-input
      id="radius"
      type="number"
      v-model.number="radius"
      class="mb-5"
      placeholder="Enter sphere radius"
    />
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
    initialAnchor: {},
  },
  data() {
    return {
      model_identifier: 'hoverlay.core.ImmersionSphere.1.0',
      inner_edge_distance: 1,
      edge_width: 0,
      grounded: true,
      camera_elevation: 1.5, // default in m
      radius: 5, // default sphere radius
    }
  },
  watch: {
    inner_edge_distance(updatedValue) {
      try {
        let abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.inner_edge_distance = updatedValue / 100
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
    edge_width(updatedValue) {
      try {
        let abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.edge_width = updatedValue / 100
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
    grounded(newVal) {
      try {
        let abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.grounded = newVal
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
    camera_elevation(newVal) {
      try {
        let abilities = JSON.parse(this.hobject.abilities)
        // Store camera_elevation in meters
        abilities.skin.camera_elevation = newVal
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
    radius(newVal) {
      try {
        let abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.radius = newVal
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
  },
  created() {
    try {
      let abilities = JSON.parse(this.hobject.abilities)
      
      if (abilities.skin.inner_edge_distance != null) {
        this.inner_edge_distance = abilities.skin.inner_edge_distance * 100
      }
      
      if (abilities.skin.edge_width != null) {
        this.edge_width = abilities.skin.edge_width * 100
      }
      
      if (abilities.skin.grounded != null) {
        this.grounded = abilities.skin.grounded
      }
      
      if (abilities.skin.camera_elevation != null) {
        this.camera_elevation = abilities.skin.camera_elevation
      } else {
        this.camera_elevation = 1.5
      }
      
      if (abilities.skin.radius != null) {
        this.radius = abilities.skin.radius
      } else {
        this.radius = 5
      }
    } catch (e) {
      console.error(e)
      // Use defaults if abilities cannot be parsed
      this.inner_edge_distance = 1
      this.edge_width = 0
      this.grounded = true
      this.camera_elevation = 1.5
      this.radius = 5
    }
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.con-vs-alert .con-vs-alert-primary {
  height: auto !important;
}
</style>