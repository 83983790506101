<!-- =========================================================================================
  File Name: uploads/Immersive360Upload.vue
  Description: Image or video 360 Upload Form. It will create a preview of the uploaded 360 using the immersive-sphere-preview component or video.
========================================================================================== -->

<template>
  <!-- tab 1 content -->
  <form data-vv-scope="step-1">
    <div class="sphere-preview" v-if="url">
      <immersive-sphere-preview
        v-multi-ref:form
        v-bind:hobject="previewHobject"
      ></immersive-sphere-preview>
    </div>
    <file-pond
      name="test"
      ref="pond"
      :maxFileSize="maxFileSize"
      :label-idle="$t('HoverpackImmersiveSphereDrag') + '(Max ' + maxFileSize + ')'"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png, video/mp4"
      :files="myFiles"
      v-on:removefile="fileRemoved"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
      :allowImagePreview="false"
    />
    <span class="text-danger">{{ errors.first('step-1.image_upload') }}</span>
    <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>
    <canvas v-show="false" id="canvas" width="640" height="480"></canvas>
  </form>
</template>

<script>
import humanize from 'string-humanize'
import * as Utils from '@/assets/js/utils/utils.js'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import imageCompression from 'browser-image-compression'
import ImmersiveSpherePreview from '@/views/hoverlay/components/objects-form/3DViewers/ImmersiveSpherePreview.vue'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
)

export default {
  inject: ['$validator'],
  components: {
    FilePond,
    ImmersiveSpherePreview
  },
  props: {
    hobject: {},
  },
  data() {
    return {
      errorMessageUpload: '',
      myFiles: [],
      url: null,
      previewHobject: {},
    }
  },
  watch: {
    hobject: {
      handler() {
        console.log('hobject changed')
        this.updatePreviewHobject(this.url, 'image');
      },
      deep: true,
    },
  },
  computed: {
    maxFileSize() {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  created() {
    if (!this.hobject.name) this.hobject.name = ''
    if (!this.hobject.abilities) {
      console.log('No abilities found')
      this.hobject.abilities =
        '{	"interactions": { "start_behavior": "enabled", "orientable_by_user": true, "resizable_by_user": true, "positionable_by_user": true}, "skin": {"auto_start":true,"loop":true,"type":"360image","force_aspect_ratio":false,"optimize_image_size":false,"inner_edge_distance":0.22,"edge_width":0.1,"camera_elevation":2,"radius":10,"grounded":true}}'
    }
    if (this.hobject.asset_uri) {
      // Copy all properties from hobject to previewHobject
      Object.keys(this.hobject).forEach(key => {
        this.$set(this.previewHobject, key, this.hobject[key]);
      });
      this.url = this.hobject.asset_uri
      this.myFiles = [
        {
          source: this.hobject.asset_uri,
          options: {
            type: 'local',
          },
        },
      ]
    }
  },
  async mounted() {
    this.url = this.hobject.asset_uri
  },
  methods: {
    fileRemoved() {
      this.url = null
      // Clear the preview hobject
      this.previewHobject.asset_uri = ''
    },
    fileAdded(error, file) {
      if (!this.hobject.name) {
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }

      // Create a temporary object URL for preview
      const tempUrl = URL.createObjectURL(file.file)
      
      this.url = tempUrl
      
      // Update preview hobject while preserving other properties
      this.updatePreviewHobject(tempUrl, 'image');
    },
    handleFilePondInit() {
    },
    validate() {
      if (this.hobject.data || this.myFiles.length > 0) {
        this.errorMessageUpload = ''
        console.log('[Immersive360Upload.vue] VALIDATION TRUE')
        return true
      } else {
        console.log('[Immersive360Upload.vue] VALIDATION FALSE')
        this.errorMessageUpload = 'You must upload an image'
        return false
      }
    },
    setHobject(hobject) {
      this.hobject = hobject
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {

      let abilities = JSON.parse(this.hobject.abilities)
      if (file.type.includes('image')) {
        abilities.skin.type = '360image'
        const compressedThumbnail = await imageCompression(
          new File([file], file.name, { type: file.type }),
          { maxSizeMB: 1, maxWidthOrHeight: 512, useWebWorker: true }
        )
        this.hobject.image = compressedThumbnail
        const compressedImage = await imageCompression(
          new File([file], file.name, { type: file.type }),
          { maxSizeMB: 5, maxWidthOrHeight: 6000, useWebWorker: true, type: 'image/jpeg' }
        )
        this.hobject.data = compressedImage
      } else {
        abilities.skin.type = '360video'
      }
      this.hobject.abilities = JSON.stringify(abilities)

      this.hobject.data = file

      load(file)
      return {
        abort: () => {
          abort()
        },
      }
    },
        /**
     * Update previewHobject when editing an existing object
     * Preserves all original hobject properties but updates the asset-uri
     * @param {String} newAssetUri - The new asset URI to preview
     * @param {String} newAssetType - The type of the new asset
     */
    updatePreviewHobject(newAssetUri, newAssetType) {
      console.log('updatePreviewHobject', newAssetUri, newAssetType);
      // First, copy all properties from original hobject
      Object.keys(this.hobject).forEach(key => {
        this.$set(this.previewHobject, key, this.hobject[key]);
      });
      
      // Then update only the specific properties needed for preview
      this.$set(this.previewHobject, 'asset_uri', newAssetUri);

    },
    tryCanvasScreenshot() {
      this.screenshotInterval = setInterval(this.takeCanvasScreenshot, 1000)
    },
    takeCanvasScreenshot() {
      try {
        const canvas = document.querySelector('canvas')
        const context = canvas.getContext('2d')
        const video = document.getElementsByClassName('filepond--media-preview')[0].children[0]
        const ratio = video.videoWidth / video.videoHeight
        const w = video.videoWidth - 100
        const h = parseInt(w / ratio, 10)
        canvas.width = w
        canvas.height = h
        context.fillRect(0, 0, w, h)
        context.drawImage(video, 0, 0, w, h)
        this.hobject.image = Utils.dataURItoBlob(canvas.toDataURL())
        console.log(this.hobject.image)
        clearInterval(this.screenshotInterval)
      } catch (e) {
        console.log(e)
      }
    },
    load(source, load) {
      fetch(new Request(source)).then(response => {
        response.blob().then(myBlob => {
          load(myBlob)
        })
      })
    },
    fetch(url, load) {
      fetch(new Request(url)).then(response => {
        response.blob().then(myBlob => {
          load(myBlob)
        })
      })
    },
    restore(uniqueFileId, load) {
      // error();
    },
    revert(uniqueFileId, load) {
      // load();
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.sphere-preview {
  height: auto; /* Set the desired height */
}
</style>
